import React from "react"
import { graphql, StaticQuery } from "gatsby"

import '../assets/css/components/contact-widget.css'

const Contact = ({ data }) => (
    <footer className="contact-widget">
        <div className="container">
            <div className="row">
                {
                    data.allStrapiContatos.edges.map(({ node }) => (
                        <div className="col-md-3 mx-auto">
                            <h5 className="contact-title">{node.cidade}</h5>
                            <p className="contact-text">
                                {node.endereco}
                                <br />
                                {node.complemento}
                                <br />
                                {node.numero}
                                <br />
                                {node.email}
                            </p>
                        </div>
                    ))
                }
            </div>
        </div>
    </footer>
)

export default function ContactWidget(props) {
    return (
        <StaticQuery
            query={graphql`
            query{
                allStrapiContatos {
                    edges {
                        node {
                            id
                            numero
                            cidade
                            endereco
                            complemento
                            email
                        }
                    }
                }
            }
            `}
            render={data => <Contact data={data} />}
        />
    )
}