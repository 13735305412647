import React, { useState } from "react";
import classNames from 'classnames';

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import { IoIosCall } from 'react-icons/io';
import { GoMailRead } from 'react-icons/go';
import { RiRoadMapLine } from 'react-icons/ri';

import ContactWidget from '../components/contactWidget'

import Layout from "../components/layout";

import '../styles/index.css';
import styles from '../styles/contact.module.css';

import axios from 'axios';

const Contato = ({ data }) => {
  const intl = useIntl();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  async function handle(event) {
    event.preventDefault();
    const url = `/.netlify/functions/${process.env.GATSBY_EMAIL_PLATFORM}-send-email`;
    await axios.post(url, {
      name,
      email,
      phone_number: phone,
      subject: title,
      message
    });

    setName('');
    setEmail('');
    setPhone('');
    setTitle('');
    setMessage('');
    alert('Formulario enviado com sucesso! Em breve retornaremos o contato.');
  };

  return (
    <Layout titlePage="Contato" renderGTM={true}>

      <div className={classNames("container", styles.contactContainer)}>
        <main className={styles.contContactMain}>

          <section className={styles.secPrimaryContact}>
            {
              data.allStrapiContatos.edges.map(({ node }) => (
                <div key={node.cidade} className={styles.boxContactPrimary}>
                  <h1 className={styles.titleContact}>{node.cidade}</h1>

                  <p className={styles.descriptionContact}><RiRoadMapLine className={styles.iconContact} />{node.endereco}<br />{node.complemento}</p>

                  <p className={styles.descriptionContact}><IoIosCall className={styles.iconContact} />{node.numero}</p>

                  <p className={styles.descriptionContact}><GoMailRead className={styles.iconContact} /><a href={'mailto:' + node.email}>{node.email}</a></p>
                </div>
              ))
            }
          </section>

          <section className={styles.secSecundaryContact}>
            <h1 className={styles.titleContact}>{intl.messages['contato.titleForm']}</h1>

            <p className={styles.descriptionContact}>{intl.messages['contato.subtitulo']}</p>

            <form onSubmit={handle} className={styles.formContact}>
              <div className={styles.row}>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} className={styles.inputForm} placeholder={intl.messages['contato.labelNome']} required />

                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className={styles.inputForm} placeholder={intl.messages['contato.labelEmail']} required />
              </div>

              <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} className={styles.inputForm} placeholder={intl.messages['contato.labelTelefone']} style={{ marginTop: 15 }} />

              <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} className={styles.inputForm} placeholder={intl.messages['contato.labelAssunto']} style={{ marginTop: 15 }} required />

              <textarea value={message} onChange={(e) => setMessage(e.target.value)} className={styles.inputForm} placeholder={intl.messages['contato.labelMensagem']} rows="5" style={{ marginTop: 15 }} required />

              <button type="submit" className={styles.btnForm}>{intl.messages['contato.labelBotao']}</button>
            </form>
            <a href="https://wa.me/5511976282854" title="Whatsapp" className={styles.wppFixed} >
              <img src="/wpp.svg" alt="" />
            </a>
          </section>

        </main>

        {
          intl.locale === 'en' &&
          <ContactWidget />
        }
      </div>
    </Layout>
  )
}

export const query = graphql`
query{
    allStrapiContatos {
        edges {
            node {
                id
                numero
                cidade
                endereco
                complemento
                email
            }
        }
    }
}
`

export default Contato
